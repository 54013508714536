<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="`Add ${$route.params.slug}`" />
        </v-col>
        <v-col md="8">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="title"
                  outlined
                  label="Title"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>

              <v-col
                cols="6"
                v-if="
                  $route.params.slug == 'ads' || $route.params.slug == 'Ads'
                "
              >
                <v-select
                  :items="adspositions"
                  label="Position"
                  v-model="position"
                  outlined
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                v-if="
                  $route.params.slug == 'ads' || $route.params.slug == 'Ads'
                "
              >
                <v-text-field
                  v-model="link"
                  outlined
                  label="Link"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                v-if="
                  $route.params.slug == 'blog' || $route.params.slug == 'Blog'
                "
              >
                <v-select
                  :items="blogCategory"
                  v-model="category"
                  label="Category"
                  outlined
                  item-text="name"
                  item-value="status"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="sort"
                  outlined
                  label="Sort"
                  class="mr-btm-minus"
                  :rules="[sortRules.nan]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  :items="statuses"
                  label="Status"
                  outlined
                  v-model="status"
                  item-text="name"
                  item-value="status"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <quill-editor
                  ref="myQuillEditor"
                  v-model="description"
                  :options="editorOption"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @ready="onEditorReady($event)"
                />
              </v-col>
              <v-col cols="12">
                <img
                  :src="`https://app.necojobs.com.np${this.imageUrl}`"
                  alt=""
                  class="imagecontent"
                />
                <image-uploader
                  :preview="true"
                  :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                  capture="environment"
                  :debug="1"
                  doNotResize="gif"
                  :autoRotate="true"
                  outputFormat="file"
                  @input="setImage"
                  class="imagebox"
                >
                  <label for="fileInput" slot="upload-label">
                    <figure>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          class="path1"
                          d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                        ></path>
                      </svg>
                    </figure>
                    <span class="upload-caption">{{
                      hasImage ? "Replace" : "Click to upload"
                    }}</span>
                  </label>
                </image-uploader>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="metaTitle"
                  outlined
                  label="Meta Title"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="metaKeywords"
                  outlined
                  label="Meta Keywords"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="metaDescription"
                  outlined
                  label="Meta Description"
                  class="mr-btm-minus"
                  rows="5"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                  v-if="!allLoadinPost"
                >
                  Add {{ $route.params.slug }}
                </v-btn>
                <v-progress-circular
                  v-if="allLoadinPost"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>

          <v-snackbar v-model="snackbar">
            <span v-if="allPost != undefined">{{ allPost.message }}</span>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import ImageUploader from "vue-image-upload-resize";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Addad",
  components: { PageHeader, ImageUploader },
  props: ["headtitle"],
  data: () => ({
    valid: true,
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    msg: "Vue Image Upload and Resize Demo",
    hasImage: false,
    image: null,
    title: "",
    description: "",
    adspositions: ["top", "bottom", "left", "right"],
    headercontent: {
      title: "Add Ad",
      addlink: "/dashboard/ads/add",
      downloadlink: true,
    },
    snackbar: false,
    editorOption: {
      // Some Quill options...
    },
    link: "",
    contentType: ["ads", "blog"],
    content: "ads",
    blogCategory: ["Career Tips", "Business Tips", "Information", "Education"],
    titleRules: [(v) => !!v || "Category Title is required"],
    statusRules: [(v) => !!v || "Status is required"],
    sortRules: {
      required: (value) => !!value || "Sort is required.",
      nan: (v) => !isNaN(v) || "Sort should be number",
    },
    descriptionRules: [(v) => !!v || "Description is required"],
    statuses: [
      { name: "Yes", status: true },
      { name: "No", status: false },
    ],
    category: "",
    sort: 0,
    status: true,
    imageUrl: null,
    id: null,
    position: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
  }),
  methods: {
    ...mapActions(["addAd", "fetchPostBySlug"]),
    async validate() {
      if (this.$refs.form.validate()) {
        let fd = new FormData();
        fd.append("title", this.title);
        fd.append("description", this.description);
        fd.append("sort", this.sort);
        fd.append("status", this.status);
        fd.append("metaTitle", this.metaTitle);
        fd.append("metaKeywords", this.metaKeywords);
        fd.append("metaDescription", this.metaDescription);
        fd.append("type", this.$route.params.slug.toLowerCase());
        if (this.category != "") {
          fd.append("category", this.category);
        }
        if (this.position != "") {
          fd.append("position", this.position);
        }
        if (this.id != null) {
          fd.append("id", this.id);
        }
        if (this.link != "") {
          fd.append("link", this.link);
        }
        if (this.image != null) {
          fd.append("image", this.image);
          fd.append("fileType", "images");
          fd.append("imageFile", "posts");
          await this.addAd(fd);
        } else {
          await this.addAd(fd);
        }
      }
      this.snackbar = true;
    },
    setImage: function (output) {
      this.hasImage = true;
      this.image = output;
    },
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
  },

  computed: {
    ...mapGetters([
      "allLoadingCurd",
      "allCategory",
      "allLoadinPost",
      "allPost",
    ]),
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },

  async created() {
    await this.fetchPostBySlug(this.$route.params.title);
    this.title = this.allPost.results.title;
    this.sort = this.allPost.results.sort;
    this.status = this.allPost.results.status;
    this.description = this.allPost.results.description;
    this.imageUrl = this.allPost.results.imageUrl;
    this.metaTitle = this.allPost.results.metaTitle;
    this.metaKeywords = this.allPost.results.metaKeywords;
    this.metaDescription = this.allPost.results.metaDescription;
    this.id = this.allPost.results._id;
    if (this.$route.params.slug == "Ads") {
      this.position = this.allPost.results.position;
      this.link = this.allPost.results.link;
    } else if (this.$route.params.slug == "Blog") {
      this.category = this.allPost.results.category;
    }

    // this.fetchCategoryBySlug(this.$route.params.slug);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 200px;
  min-height: 50px;
}

.imagebox {
  display: flex;
  max-width: 30%;
  // background: rgb(248, 248, 248);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 20px;
  label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
.imagecontent {
  width: 200px;
  margin: 20px 0;
}
</style>
